<template>
    <div class="container-card-cuota" v-if="comercioCredito">
      <div v-if="comercioCredito.totalDebt > 0 || comercioCredito.minimumPayment > 0">
        <div class="step-icon bg-white">
          <img src="../assets/icon-wallet.svg" :alt="comercioCredito.alias">
        </div>
        <h2 class="title mb-4">Selecciona el valor a pagar</h2>
        <p class="subtitle-container">
          Selecciona una de las siguientes opciones para realizar tu pago:
        </p>
      </div>
      <div class="container-form" v-if="comercioCredito.totalDebt > 0 || comercioCredito.minimumPayment > 0">
        <div>
          <div class="button-pay-quota pp-quac" v-if="comercioCredito.prontopagos.facturas.length > 0" @click="irAProntopago()">
            <div class="col pl-0">
              <p class="value-pay">Pronto pago</p>
              <p style="font-size: 15px;">Obtén un descuento por pago anticipado.</p>
            </div>
            <img src="@/assets/arrow-pay.svg" style="max-width: 9px;">
          </div>
          <div class="button-pay-quota" @click="pagarCuotaAbono(comercioCredito.minimumPayment)" :class="{'button-inactivo': comercioCredito.minimumPayment <= 0}">
            <div class="col pl-0">
              <p>Pago mínimo</p>
              <p class="value-pay" v-text="numberFormat(comercioCredito.minimumPayment)"></p>
            </div>
            <img src="@/assets/arrow-pay.svg" style="max-width: 9px;">
          </div>
          <div class="button-pay-quota" @click="pagarCuotaAbono(comercioCredito.totalDebt)" :class="{'button-inactivo': comercioCredito.totalDebt <= 0}">
            <div class="col pl-0">
              <p>Pago total</p>
              <p class="value-pay" v-text="numberFormat(comercioCredito.totalDebt)"></p>
            </div>
            <img src="@/assets/arrow-pay.svg" style="max-width: 9px">
          </div>
          <div class="button-pay-quota" @click="agregarValorPersonalizado()">
            <div class="col pl-0">
              <p class="value-pay">Otro valor</p>
            </div>
            <img src="@/assets/arrow-pay.svg" style="max-width: 9px">
          </div>
          <div class="cont_link_volver" v-if="comerciosCredito.length > 1">
            <a href="#" @click.prevent="$router.back()">Volver</a>
          </div>
        </div>
        <ErrorsApi/>
      </div>
      <div class="container-form" v-if="comercioCredito.totalDebt == 0 && comercioCredito.minimumPayment == 0">
        <img class="mb-4" src="../assets/duck_quac_happy.svg" alt="imagen del pato quac">
        <h2 class="title mb-4">¡Felicidades! tu crédito se encuentra al día</h2>
        <div class="container-card-up-to-day">
          <p class="badge-up-to-day"><span>Al día</span></p>
          <p class="text-card-up-to-day">
            Aprovecha tu crédito disponible en nuestros comercios aliados y disfruta de ofertas exclusivas.
          </p>
        </div>
      </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi'
export default{
  components: {
    ErrorsApi,
  },
  computed:{
    ...mapState(['porcentajeDescuentoProntopago','clienteNombre','pagoMinimo','totalDeuda','celularCliente','documentoCliente','facturasProntopago','beneficioQuac','comercioCredito', 'comerciosCredito'])
  },
  mounted(){
    this.gestionarValidarTokenSMS(null);
    this.gestionarValidarEnvioTokenSMS(null);
    this.agregarIdProntoPago('');
    this.beneficioQuacSeleccionado(0);
    this.gestionarShowArrowBack(true);
    if (this.$store.state.tempVal && this.$store.state.tempVal <= this.comercioCredito.totalDebt) {
      this.agregarValorAbono(this.$store.state.tempVal);
      this.agregarProcesoAnterior('seleccionar-cuota');
      this.cambiarProceso('metodo-pago');
      this.$store.commit('setTempVal', null);
    }
  },
  methods:{
    ...mapActions(['numberFormat','generarTokenEstadoCuenta','agregarProcesoAnterior']),
    ...mapMutations(['cambiarProceso','agregarValorAbono','agregarProcesoAnterior','agregarIdProntoPago','gestionarValidarTokenSMS','gestionarShowArrowBack','gestionarValidarEnvioTokenSMS','beneficioQuacSeleccionado']),
    numberFormat(val){
      return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
    },
    pagarCuotaAbono(valor){
      if(valor <= 0) return;
      this.agregarValorAbono(valor);
      this.agregarProcesoAnterior('seleccionar-cuota');
      this.cambiarProceso('metodo-pago');
    },
    agregarValorPersonalizado(){
      this.agregarProcesoAnterior('seleccionar-cuota');
      this.cambiarProceso('valor-personalizado');
    },
    irAProntopago() {
      this.agregarProcesoAnterior('seleccionar-cuota');
      this.cambiarProceso('pago-anticipado')
    }
  }
}
</script>
<style lang="scss" scoped>
.state-acount{
  border: 2px transparent solid;
}
.state-acount:hover{
  border: 2px #802EFF solid;
  cursor: pointer;
}
.button-pay-quota {
  background: linear-gradient(90deg, white 88%, #e9f0fd 0);
  &.pp-quac {
    background: linear-gradient(90deg, white 88%, #E7E6FC 0);
    border-color: #E7E6FC;
    &:hover{
      border-color: #802EFF;
    }
  }
}
.button-pay-quota.anticipado{
  border: 2px transparent solid;
}
.button-pay-quota.anticipado:hover{
  border: 2px #FFC107 solid;
}
.cupo_al_dia{
  background-color: #E1F6EA;
  padding: 1rem;
}
.container_info_credito{
  background-color:#F0F0FF;
  padding: 1rem;
  border-radius:10px;
}
.container-card-up-to-day {
  background-color: #e9e8f2;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px #00000010;
  .badge-up-to-day {
    span {
      background-color: #00da9c;
      margin-right: auto;
      margin-left: auto;
      padding: .1rem .7rem;
      border-radius: 10px;
    }
  }
  .text-card-up-to-day {
    line-height: 1.4;
    margin-bottom: 0;

  }
}
</style>