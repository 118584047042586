<template>
    <div class="container-card-cuota" style="max-width: 430px">
        <h2 class="mb-3">Método de pago seleccionado</h2>

        <div class="container-method-payment d-flex justify-content-between align-items-center px-3 rounded mb-5 mx-auto">
            <div class="d-flex align-items-center">
                <div class="mr-3">
                    <img src="@/assets/icon-efecty-simple.svg" width="30">
                </div>
                <div class="text-left">
                    <p class="m-0 font-weight-bold" style="font-size: 18px;line-height: 1.1;">Efecty</p>
                    <p class="m-0" style="font-size: 14px">Paga en efectivo en puntos efecty.</p>
                </div>
            </div>
            <div class="text-right">
                <p class="m-0" style="font-size: 14px;line-height: 1.1;">Total a pagar</p>
                <p class="m-0 font-weight-bold" style="font-size: 18px" v-text="numberFormat(this.valorAbono)"></p>
            </div>
        </div>

        <div class="container-form">
            
            <p class="mb-5" v-if="valorAbono >= 20000"><b>¡Información importante!</b> <br> Recuerda realizar el pago el día de <b>hoy antes de las 4:30 pm</b>, de lo contrario se podrán generar intereses de mora.</p>
            <p v-else><b>Recuerda:</b> El medio de pago seleccionado solo permite <b>pagos iguales o superiores a $20.000</b>. Por favor ingresa un valor válido para poder procesar tu pago.</p>
    
            <button class="button-primary mb-3" @click="pagarCuotaEfecty()" :disabled="valorAbono < 20000">Pagar con Efecty</button>
    
            <div class="cont_link_volver">
                <a href="#" @click.prevent="$router.back()">Volver</a>
            </div>
        </div>
        <ErrorsApi />


    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi.vue';
export default{
    components:{
      ErrorsApi,
    },
    mounted() {
      this.gestionarValidarTokenSMS(null);
    },
    computed:{
        ...mapState(['valorAbono'])
    },
    methods:{
        ...mapMutations(['cambiarProceso','gestionarValidarTokenSMS']),
        ...mapActions(['pagarCuotaEfecty']),
        numberFormat(val){
            return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
        },
    }
}
</script>
<style lang="scss">
.container-method-payment{
    background-color: #E9F0FD;
    min-height: 70px;
    max-width: 430px;
}
</style>